<template>
<!-- 专家回答问题 -->
  <div class="container">
    <div class="form">
      <van-field
        readonly
        :value="form.title"
        label="标题"
        label-width="4.2em"
      />

       <van-field
        readonly
        :value="form.place"
        label="位置"
        label-width="4.2em"
      />

      <van-field name="imgList" label-width="4.2em" label="图片" readonly>
        <template #input>
          <div v-if="imgList.length == 0">无</div>
          <div>
            <van-image
              v-for="(item, index) in imgList"
              :key="index"
              height="80"
              fit="contain"
              :src="item"
              @click="imagePreviewWarn(index)"
            />
          </div>
        </template>
      </van-field>
      <van-field
        readonly
        label="内容"
        label-width="4.2em"
      >
        <template #input>
          <span>{{form.content}}</span>
        </template>
      </van-field>
    </div>

  </div>
</template>
<script>
import { getAskDetail } from '@/api/eventApi'
import { ImagePreview } from 'vant';
export default {
  data() {
    return {
        form: {
          title: '',
          content: '',
        },
        type: '',
        list: [],
        imgList: []
    }
  },
  created() {
    this.form.id = this.$route.query.id
    this.getDetail(this.$route.query.id)
  },
  mounted() {},
  methods: {
    imagePreviewWarn(index){
      ImagePreview({
        images: this.imgList,
        startPosition: index,
      });
    },
    getDetail(id) {
      getAskDetail({
        id: id
      }).then(res => {
        this.form.title = res.data.title
        this.form.createTime = res.data.createTime
        this.form.content = res.data.content
        this.form.isGrade = res.data.isGrade
        this.form.replyNum = res.data.replyNum
        this.form.place = res.data.place
        this.imgList = res.data.friendUrl?res.data.friendUrl.split(','):[]
      })
    },
    
  },
}
</script>
<style scoped lang="scss">
// @import '@/assets/css/form.css';
.container {
  width: 100%;
  box-sizing: border-box;
  padding: 24px 5px;
  font-size: 14px;
  background-color: #fff;
}
.van-cell::after{
  border-bottom: none;
}
.x-van-group{
  box-sizing: border-box;
  padding: 10px 0;
  border-bottom: 1px solid #E4E4E4;
}
.x-van-group:last-child{
  border:none;
}
.van-image{
  width: 33.33%;
  margin:0 5px;
}
</style>
